<template>
  <ion-page>   
    <ion-content :fullscreen="true">

      <Header></Header>
        
      <div class="container">
        <section id="options" class="list-nav mt-lg mb-md">
          <ion-grid>
            <ion-row>
              <ion-col size="12" size-lg="6">
                <h4>Empresa</h4>
                <h1>Configuración</h1>
              </ion-col>
            </ion-row>
            <!--<ion-row>
              <ion-col size="12">
                 <ion-segment scrollable mode="ios" @ionChange="changeCategory($event)" :value="selectedCategory" class="segmentos">
                        <ion-segment-button value="restaurant" v-if="evolbeUser.business[0].modules.includes('restaurant')">
                            <ion-label>Restaurante</ion-label>
                        </ion-segment-button>-->
                        <!--<ion-segment-button value="experiencias-121" v-if="evolbeUser.business[0].modules.includes('experiencias-121')">
                            <ion-label>Experiencias 12+1</ion-label>
                        </ion-segment-button>-->
                    <!--</ion-segment>
              </ion-col>
            </ion-row>-->
            <ion-row style="margin-top: 1rem">
              <ion-col size="12" size-lg="6">
                <div class="flex">
                  <ion-button @click="exit" shape="round" color="dark" fill="outline">
                      <ion-icon slot="start" :icon="arrowBackOutline"></ion-icon>
                      <ion-label>Volver</ion-label>
                  </ion-button>
                  <ion-button @click="save" color="primary" style="margin-left:0.75rem" shape="round">
                      <ion-icon slot="start" :icon="saveOutline"></ion-icon>
                      <ion-label>Guardar</ion-label>
                  </ion-button>
                </div>
              </ion-col>
              <ion-col size="12" size-lg="6">
              </ion-col>
            </ion-row>
        </ion-grid>
        </section>

        <section id="content">
          <ion-grid class="mb-lg">
            <ion-row>
              <ion-col size="12" size-lg="8">
                <ion-grid v-if="selectedCategory === 'restaurant'">
                    <ion-row class="mb-md">
                        <h5 class="edit-title">Horario</h5>
                        <ion-col size="12">
                            <ion-list lines="none" class="horarios">
                                <ion-item>
                                    <p slot="start">Lunes</p>
                                    <ion-toggle slot="start" color="success" :checked="openingHours.week[1]" @ionChange="toggleOpeningDay($event, 1)"></ion-toggle>
                                    <ion-grid style="margin-left: 1rem" v-if="openingHours.week[1]">
                                        <ion-row>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[1].morning.open" placeholder="M: Apertura" optionLabel="label" optionValue="value" class="input secondary-input" :showClear="true" />
                                            </ion-col>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[1].morning.close" placeholder="M: Cierre" optionLabel="label" optionValue="value" class="input" :showClear="true" />
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[1].night.open" placeholder="T: Apertura"  optionLabel="label" optionValue="value" class="input secondary-input" :showClear="true" />
                                            </ion-col>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[1].night.close" placeholder="T: Cierre" optionLabel="label" optionValue="value" class="input" :showClear="true" />
                                            </ion-col>
                                        </ion-row>
                                    </ion-grid>
                                </ion-item>
                                <ion-item>
                                    <p slot="start">Martes</p>
                                    <ion-toggle slot="start" color="success" :checked="openingHours.week[2]" @ionChange="toggleOpeningDay($event, 2)"></ion-toggle>
                                    <ion-grid style="margin-left: 1rem" v-if="openingHours.week[2]">
                                        <ion-row>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[2].morning.open" placeholder="M: Apertura" optionLabel="label" optionValue="value" class="input secondary-input" :showClear="true" />
                                            </ion-col>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[2].morning.close" placeholder="M: Cierre" optionLabel="label" optionValue="value" class="input" :showClear="true" />
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[2].night.open" placeholder="T: Apertura"  optionLabel="label" optionValue="value" class="input secondary-input" :showClear="true" />
                                            </ion-col>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[2].night.close" placeholder="T: Cierre" optionLabel="label" optionValue="value" class="input" :showClear="true" />
                                            </ion-col>
                                        </ion-row>
                                    </ion-grid>
                                </ion-item>
                                <ion-item>
                                    <p slot="start">Miércoles</p>
                                    <ion-toggle slot="start" color="success" :checked="openingHours.week[3]" @ionChange="toggleOpeningDay($event, 3)"></ion-toggle>
                                    <ion-grid style="margin-left: 1rem" v-if="openingHours.week[3]">
                                        <ion-row>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[3].morning.open" placeholder="M: Apertura" optionLabel="label" optionValue="value" class="input secondary-input" :showClear="true" />
                                            </ion-col>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[3].morning.close" placeholder="M: Cierre" optionLabel="label" optionValue="value" class="input" :showClear="true" />
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[3].night.open" placeholder="T: Apertura"  optionLabel="label" optionValue="value" class="input secondary-input" :showClear="true" />
                                            </ion-col>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[3].night.close" placeholder="T: Cierre" optionLabel="label" optionValue="value" class="input" :showClear="true" />
                                            </ion-col>
                                        </ion-row>
                                    </ion-grid>
                                </ion-item>
                                <ion-item>
                                    <p slot="start">Jueves</p>
                                    <ion-toggle slot="start" color="success" :checked="openingHours.week[4]" @ionChange="toggleOpeningDay($event, 4)"></ion-toggle>
                                    <ion-grid style="margin-left: 1rem" v-if="openingHours.week[4]">
                                        <ion-row>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[4].morning.open" placeholder="M: Apertura" optionLabel="label" optionValue="value" class="input secondary-input" :showClear="true" />
                                            </ion-col>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[4].morning.close" placeholder="M: Cierre" optionLabel="label" optionValue="value" class="input" :showClear="true" />
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[4].night.open" placeholder="T: Apertura"  optionLabel="label" optionValue="value" class="input secondary-input" :showClear="true" />
                                            </ion-col>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[4].night.close" placeholder="T: Cierre" optionLabel="label" optionValue="value" class="input" :showClear="true" />
                                            </ion-col>
                                        </ion-row>
                                    </ion-grid>
                                </ion-item>
                                <ion-item>
                                    <p slot="start">Viernes</p>
                                    <ion-toggle slot="start" color="success" :checked="openingHours.week[5]" @ionChange="toggleOpeningDay($event, 5)"></ion-toggle>
                                    <ion-grid style="margin-left: 1rem" v-if="openingHours.week[5]">
                                        <ion-row>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[5].morning.open" placeholder="M: Apertura" optionLabel="label" optionValue="value" class="input secondary-input" :showClear="true" />
                                            </ion-col>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[5].morning.close" placeholder="M: Cierre" optionLabel="label" optionValue="value" class="input" :showClear="true" />
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[5].night.open" placeholder="T: Apertura"  optionLabel="label" optionValue="value" class="input secondary-input" :showClear="true" />
                                            </ion-col>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[5].night.close" placeholder="T: Cierre" optionLabel="label" optionValue="value" class="input" :showClear="true" />
                                            </ion-col>
                                        </ion-row>
                                    </ion-grid>
                                </ion-item>
                                <ion-item>
                                    <p slot="start">Sábado</p>
                                    <ion-toggle slot="start" color="success" :checked="openingHours.week[6]" @ionChange="toggleOpeningDay($event, 6)"></ion-toggle>
                                    <ion-grid style="margin-left: 1rem" v-if="openingHours.week[6]">
                                        <ion-row>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[6].morning.open" placeholder="M: Apertura" optionLabel="label" optionValue="value" class="input secondary-input" :showClear="true" />
                                            </ion-col>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[6].morning.close" placeholder="M: Cierre" optionLabel="label" optionValue="value" class="input" :showClear="true" />
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[6].night.open" placeholder="T: Apertura"  optionLabel="label" optionValue="value" class="input secondary-input" :showClear="true" />
                                            </ion-col>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[6].night.close" placeholder="T: Cierre" optionLabel="label" optionValue="value" class="input" :showClear="true" />
                                            </ion-col>
                                        </ion-row>
                                    </ion-grid>
                                </ion-item>
                                <ion-item>
                                    <p slot="start">Domingo</p>
                                    <ion-toggle slot="start" color="success" :checked="openingHours.week[7]" @ionChange="toggleOpeningDay($event, 7)"></ion-toggle>
                                    <ion-grid style="margin-left: 1rem" v-if="openingHours.week[7]">
                                        <ion-row>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[7].morning.open" placeholder="M: Apertura" optionLabel="label" optionValue="value" class="input secondary-input" :showClear="true" />
                                            </ion-col>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[7].morning.close" placeholder="M: Cierre" optionLabel="label" optionValue="value" class="input" :showClear="true" />
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[7].night.open" placeholder="T: Apertura"  optionLabel="label" optionValue="value" class="input secondary-input" :showClear="true" />
                                            </ion-col>
                                            <ion-col size="12" size-lg="6" class="box-input">
                                                <Dropdown :options="horas" v-model="openingHours.week[7].night.close" placeholder="T: Cierre" optionLabel="label" optionValue="value" class="input" :showClear="true" />
                                            </ion-col>
                                        </ion-row>
                                    </ion-grid>
                                </ion-item>
                            </ion-list>
                        </ion-col>
                    </ion-row>

                    <ion-row class="mb-md">
                        <h5 class="edit-title">Opciones de Pedidos</h5>
                        <ion-col size="12">
                            <ion-list lines="none">
                                <ion-item>
                                    <ion-label>Permitir EXCLUSIVAMENTE la recogida de pedidos</ion-label>
                                    <ion-toggle slot="end" color="success" v-model="openingHours.onlyPickup"></ion-toggle>
                                </ion-item>
                                <ion-item>
                                    <ion-label slot="start" text-wrap>Limite de pedidos activos simultaneamente</ion-label>
                                    <InputNumber id="ordersLimit" class="inputNumber" v-model.lazy="shipping.limit" :min="0" showButtons />
                                </ion-item>
                                <ion-item>
                                    <ion-label slot="start" text-wrap>Envíos GRATIS para pedidos superiones a:</ion-label>
                                    <InputNumber id="freeShipping" class="inputNumber" v-model.lazy="shipping.freeShipping" :min="0" showButtons mode="currency" currency="EUR" locale="es-ES" />
                                </ion-item>

                            </ion-list>
                        </ion-col>
                    </ion-row>

                    <ion-row class="mb-md">
                      <ion-col size="12">
                        <h5 class="edit-title">Gastos de Envíos</h5>
                        <p>* Disponible en la proxima actualización de la App</p>
                      </ion-col>
                        <!--<ion-col size="12">
                        <ion-item lines="none">
                            <ion-label><b>Códigos postales</b></ion-label>
                            <ion-buttons slot="end">
                                <ion-button @click="addVariation" >
                                    <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                                </ion-button>
                            </ion-buttons>
                        </ion-item>

                        <DataTable :value="shipping.costs" @rowReorder="reorderShippingCosts($event, slotProps.data.key)" @cell-edit-complete="onCellEditVariationComplete" dataKey="key" editMode="cell" class="variations p-datatable-sm edit-datatable" responsiveLayout="scroll">
                          <template #empty>
                              No hay variaciones de este producto
                          </template>
                          
                          <Column :rowReorder="true" :reorderableColumn="false" style="cursor: move;" />

                          <Column field="zipCode" header="Código Postal">
                            <template #editor="slotProps">
                                <InputText v-model="slotProps.data[slotProps.column.props.field]" />
                            </template>
                          </Column>

                          <Column field="cost" header="Coste">
                            <template #editor="slotProps">
                              <InputNumber id="cost" class="inputNumber" v-model="slotProps.data[slotProps.column.props.field]" :min="0" showButtons mode="currency" currency="EUR" locale="es-ES" />
                            </template>
                          </Column>

                          <Column alignFrozen="right">
                              <template #body="slotProps"> 
                                <ion-buttons style="justify-content: flex-end">
                                    <ion-button @click="addOption($event, slotProps.data.key)" >
                                        <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                                    </ion-button>
                                    <ion-button @click="removeVariation($event, slotProps.data.key)" >
                                        <ion-icon slot="icon-only" :icon="removeCircleOutline"></ion-icon>
                                    </ion-button>
                                </ion-buttons>
                              </template>
                          </Column>
                      </DataTable>
                    </ion-col>-->
                    </ion-row>
                </ion-grid>

                <!-- Sin Configuraciones -->
                <ion-grid v-if="!selectedCategory">
                  <ion-row>
                    <ion-col size="12">
                      <p>*No hay configuraciones disponibles.</p>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
              <ion-col size="12" size-lg="4">
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>
      </div>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonIcon, IonLabel, IonButton, IonButtons, IonList, IonItem, IonSegment, IonSegmentButton, IonToggle } from '@ionic/vue';
import { saveOutline, trashOutline, arrowBackOutline, moveOutline, addCircleOutline, removeCircleOutline, closeCircleOutline } from 'ionicons/icons';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';

import InputNumber from 'primevue/inputnumber';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/i18n-validators'

import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'BusinessSettings',
  components: {
    IonContent,
    IonPage,
    Header,
    IonGrid,
    IonRow,
    IonCol,
    Footer,
    IonIcon,
    IonLabel,
    IonButton,
    IonList, 
    IonItem,
    //IonButtons,
    IonToggle,

    Dropdown,
    InputNumber,
    //DataTable,
    //Column,
    //InputText,

    //IonSegment,
    //IonSegmentButton,
  },
  data() {
      return {
        selectedCategory: undefined,

        openingHours: {
          experiencias: [],
          onlyPickup: false,
          week: {},
        },
        shipping: {
          freeShipping: 1000,
          limit: 100,
        },

        horas: [
          {
            label: '00:00',
            value: 0,
          },
          {
            label: '01:00',
            value: 1,
          },
          {
            label: '02:00',
            value: 2,
          },
          {
            label: '03:00',
            value: 3,
          },
          {
            label: '04:00',
            value: 4,
          },
          {
            label: '05:00',
            value: 5,
          },
          {
            label: '06:00',
            value: 6,
          },
          {
            label: '07:00',
            value: 7,
          },
          {
            label: '08:00',
            value: 8,
          },
          {
            label: '09:00',
            value: 9,
          },
          {
            label: '10:00',
            value: 10,
          },
          {
            label: '11:00',
            value: 11,
          },
          {
            label: '12:00',
            value: 12,
          },
          {
            label: '13:00',
            value: 13,
          },
          {
            label: '14:00',
            value: 14,
          },
          {
            label: '15:00',
            value: 15,
          },
          {
            label: '16:00',
            value: 16,
          },
          {
            label: '17:00',
            value: 17,
          },
          {
            label: '18:00',
            value: 18,
          },
          {
            label: '19:00',
            value: 19,
          },
          {
            label: '20:00',
            value: 20,
          },
          {
            label: '21:00',
            value: 21,
          },
          {
            label: '22:00',
            value: 22,
          },
          {
            label: '23:00',
            value: 23,
          },
        ]

      };
    },
    validations() {
        return {

        }
    },
    computed: {
        ...mapState(['evolbeUser', 'settings'])
    },
    methods: {
      ...mapActions(['uploadImage', 'uploadImageMenu', 'getSettings', 'updateSettings']),

      // Shipping Costs
      addVariation(){
        this.element.variations.push({
            "key": (this.lastVariationKey + 1),
            "name":"Nueva Variación",
            "lastOptionKey": 0,
            "options": [
                {
                    "key": (this.lastVariationKey + 1) + "-0",
                    "text": "Nueva Opción",
                    "value": 0,
                },
            ]
        });

        this.lastVariationKey = this.lastVariationKey + 1;
      },
      removeVariation(event, key){
        const pos = this.element.variations.findIndex(x => x.key === key);
        this.element.variations.splice(pos, 1);

        this.lastVariationKey = this.lastVariationKey - 1;
      },

      onCellEditVariationComplete(event){
        this.element.variations[event.index][event.field] = event.newValue;
      },

      reorderShippingCosts(event, key){
        const temp = this.element.steps[key].dishes[event.dragIndex];
        this.element.steps[key].dishes.splice(event.dragIndex, 1);
        this.element.steps[key].dishes.splice(event.dropIndex, 0, temp);
      },

      // Week Hours
      toggleOpeningDay(event,day){
        if (event.detail.checked) {
          if(!this.openingHours.week[day]){
            this.openingHours.week[day] = {
              morning: {},
              night: {}
            };
          }
        } else {
          this.openingHours.week[day] = undefined;
        }
      },

      // Actions
      save(){
        this.updateSettings({data: this.openingHours, id: 'opening-hours'});
        this.updateSettings({data: this.shipping, id: 'shipping'});
        this.exit();
      },

      exit(){
          this.$router.push('/');
      },
    },
    beforeMount(){
      this.getSettings().then(() => {
        if(this.settings){
          this.openingHours = this.settings.find(x => x.objectID === 'opening-hours');
          this.shipping = this.settings.find(x => x.objectID === 'shipping');
        }
      });

      if(this.evolbeUser.business[0].modules.includes('restaurant')){
        this.selectedCategory = 'restaurant';
      } else if(this.evolbeUser.business[0].modules.includes('shop')){
        this.selectedCategory = 'shop';
      }
    },
  setup() {
    return { v$: useVuelidate(), saveOutline, trashOutline, arrowBackOutline, moveOutline, addCircleOutline, removeCircleOutline, closeCircleOutline};
  }
});
</script>

<style scoped>

  .edit-title{
    padding-bottom: 1rem;
  }

  .horarios p{
    width: 80px;
    margin-right: 10px;
  }

</style>